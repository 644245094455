<template>
  <div>
  <top-data></top-data>
  <div class="overviewCommand">
    <normal-card title="球场信息" class="mt-5">
      <el-form ref="form" :model="form" :rules="rules" label-width="150px" class="field-info-form">
        <el-form-item label="球场名称：" prop="fieldName">
          <el-input v-model="form.fieldName" maxlength="15" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="电话号码：" prop="mobile">
          <el-input v-model="form.mobile" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="球场地址：">
          <el-input v-model="form.address" disabled></el-input>
        </el-form-item>
        <el-form-item label="配置：">
          <el-select v-model="serviceArr" multiple placeholder="请选择" style="width:100%">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公告栏:">
          <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 10 }" placeholder="请输入内容" v-model="form.remark">
          </el-input>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="球场门图：" prop="infrastructure.logo">
              <f-image-upload v-model="form.infrastructure.logo" @input="changeLogo" style="margin-left: 0"
                :width="'100px'" :height="'100px'"></f-image-upload>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="球场内部：" prop="infrastructure.outside">
              <f-image-upload v-model="form.infrastructure.outside" @input="changeImage2" style="margin-left: 0"
                :width="'100px'" :height="'100px'"></f-image-upload>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="场地细节：" prop="infrastructure.inside">
              <f-image-upload v-model="form.infrastructure.inside" @input="changeImage3" style="margin-left: 0"
                :width="'100px'" :height="'100px'"></f-image-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </normal-card>
    <div class="bottom-btn">
      <el-button type="primary" class="save-btn" @click="onSubmit">保存</el-button>
    </div>
  </div>
</div>
</template>

<script>
import FImageUpload from '@/common/components/Upload/f-image-upload.vue';
import normalCard from '@/common/components/normal/NormalCard';
import TopData from '../../common/components/normal/TopData.vue';
export default {
  components: {
    FImageUpload, normalCard,TopData
  },
  data() {
    return {
      options: [
        {
          value: '厕所',
          label: '厕所',
        },
        {
          value: 'WI-FI',
          label: 'WI-FI',
        },
        {
          value: '休息室',
          label: '休息室',
        },
        {
          value: '淋浴室',
          label: '淋浴室',
        },
        {
          value: '更衣室',
          label: '更衣室',
        },
        {
          value: '储物柜',
          label: '储物柜',
        },
        {
          value: '天然草',
          label: '天然草',
        },
        {
          value: '饮料售卖',
          label: '饮料售卖',
        },
        {
          value: '免费停车场',
          label: '免费停车场',
        },
        {
          value: '收费停车场',
          label: '收费停车场',
        },
      ],
      serviceArr: [],
      form: {
        infrastructure: {
          logo: '',
          inside: '',
          outside: ''
        }
      },
      rules: {
        fieldName: [
          { required: true, message: '请输入球场名称', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          {
            pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        'infrastructure.logo': [
          { required: true, message: '请上传', trigger: 'change' },
        ],
        'infrastructure.outside': [
          { required: true, message: '请上传', trigger: 'change' },
        ],
        'infrastructure.inside': [
          { required: true, message: '请上传', trigger: 'change' },
        ]
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const { res } = await this.$http.get('/Field/GetFieldInfo');
      this.form = res.data || {};
      // 如果没有图片，手动赋值为""
      if (!this.form.infrastructure) {
        this.form.infrastructure = {
          logo: '',
          inside: '',
          outside: ''
        }
      }
      // 如果有服务不为null，拆分，
      this.serviceArr = this.form.services ? this.form.services.split(',') : []
    },

    // 修改球场门图
    changeLogo(v) {
      console.log(v);
    },
    changeImage2(v) {
      console.log(v);
    },
    changeImage3(v) {
      console.log(v);
    },

    // 提交表单
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return
        }
        this.$confirm('确认保存球场信息吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.form.services = this.serviceArr.join(',');
          const { res } = await this.$http.post('/Field/SaveZqxField', this.form);
          if (res.isSuccess) {
            this.$showSuccess('修改成功');
            this.init();
          }
        })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.overviewCommand {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;

  .bread-crumb {
    height: 30px;
  }

  .overviewCommand_detail {
    height: calc(100% - 40px);
    padding: 20px;
    background-color: #fff;

    .field-info-form {
      width: 47%;
      margin-top: 20px;
    }
  }
}

.group-name {
  display: flex;
  align-items: center;

  .group {
    width: 5px;
    height: 22px;
    background-color: #134f7a;
    border-radius: 2px;
  }

  .name {
    margin-left: 10px;
    font-weight: 700;
    font-size: 20px;
  }
}

.save-btn {
  width: 200px;
  margin-top: 20px;
  background-color: #134f7a;
  color: #fff;
  border: none;
}

.bottom-btn {
  display: flex;
  justify-content: space-around;
  align-content: center;
  margin-top: 10px;
}

</style>
